var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"title","placeholder":"Enter Your Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description","placeholder":"Enter Your Description","rows":"4"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Marketplace Section Title","label-for":"marketplace_section_title"}},[_c('validation-provider',{attrs:{"name":"marketplace_section_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"marketplace_section_title","state":errors.length > 0 ? false : null,"name":"marketplace_section_title","placeholder":"Enter Your Marketplace Section Title"},model:{value:(_vm.form.marketplace_section_title),callback:function ($$v) {_vm.$set(_vm.form, "marketplace_section_title", $$v)},expression:"form.marketplace_section_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Marketplace Section Description","label-for":"marketplace_section_description"}},[_c('validation-provider',{attrs:{"name":"marketplace_section_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"marketplace_section_description","state":errors.length > 0 ? false : null,"name":"marketplace_section_description","placeholder":"Enter Your Marketplace Section Description","rows":"4"},model:{value:(_vm.form.marketplace_section_description),callback:function ($$v) {_vm.$set(_vm.form, "marketplace_section_description", $$v)},expression:"form.marketplace_section_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Select Marketplace","label-for":"marketplace_id"}},[_c('validation-provider',{attrs:{"name":"marketplace_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"marketplace_id","placeholder":"Select Marketplace","multiple":"","options":_vm.marketplaces,"label":"name","reduce":function (option) { return option.id; }},model:{value:(_vm.form.marketplace_id),callback:function ($$v) {_vm.$set(_vm.form, "marketplace_id", $$v)},expression:"form.marketplace_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"border rounded p-2 mb-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v("Video Setting")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.video_thumbnail,"height":"174","width":"310"}})],1),_c('b-media-body',[_c('b-form-group',{attrs:{"label":"Video Thumbnail","label-for":"video_thumbnail"}},[_c('b-form-file',{attrs:{"id":"video_thumbnail","accept":".jpg, .png, .gif","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.loadImage($event)}}})],1),_c('b-form-group',{attrs:{"label":"Video ID","label-for":"video_id"}},[_c('validation-provider',{attrs:{"name":"video_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"video_id","state":errors.length > 0 ? false : null,"name":"video_id","placeholder":"Enter Your Video ID"},model:{value:(_vm.form.video_id),callback:function ($$v) {_vm.$set(_vm.form, "video_id", $$v)},expression:"form.video_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])]),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Update ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }