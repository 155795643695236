var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"title","placeholder":"Enter Your Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description","placeholder":"Enter Your Description","rows":"4"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Freelancing For Whom Title","label-for":"freelancing_for_whom_title"}},[_c('validation-provider',{attrs:{"name":"freelancing_for_whom_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"freelancing_for_whom_title","state":errors.length > 0 ? false : null,"name":"freelancing_for_whom_title","placeholder":"Enter Your Freelancing For Whom Title"},model:{value:(_vm.form.freelancing_for_whom_title),callback:function ($$v) {_vm.$set(_vm.form, "freelancing_for_whom_title", $$v)},expression:"form.freelancing_for_whom_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Enter Your Freelancing For Whom Description","label-for":"freelancing_for_whom_description"}},[_c('validation-provider',{attrs:{"name":"freelancing_for_whom_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"freelancing_for_whom_description","state":errors.length > 0 ? false : null,"name":"freelancing_for_whom_description","placeholder":"Enter Your Enter Your Freelancing For Whom Description","rows":"4"},model:{value:(_vm.form.freelancing_for_whom_description),callback:function ($$v) {_vm.$set(_vm.form, "freelancing_for_whom_description", $$v)},expression:"form.freelancing_for_whom_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Select Work For Whom","label-for":"work_for_whom_id"}},[_c('validation-provider',{attrs:{"name":"work_for_whom_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"work_for_whom_id","placeholder":"Select Work For Whom","multiple":"","options":_vm.freelancing_for_whoms,"label":"name","reduce":function (option) { return option.id; }},model:{value:(_vm.form.work_for_whom_id),callback:function ($$v) {_vm.$set(_vm.form, "work_for_whom_id", $$v)},expression:"form.work_for_whom_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Cit Initiatives Section Title","label-for":"cit_initiatives_section_title"}},[_c('validation-provider',{attrs:{"name":"cit_initiatives_section_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cit_initiatives_section_title","state":errors.length > 0 ? false : null,"name":"cit_initiatives_section_title","placeholder":"Enter Your Cit Initiatives Section Title"},model:{value:(_vm.form.cit_initiatives_section_title),callback:function ($$v) {_vm.$set(_vm.form, "cit_initiatives_section_title", $$v)},expression:"form.cit_initiatives_section_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Favorite Course Section Title","label-for":"favorite_course_title"}},[_c('validation-provider',{attrs:{"name":"favorite_course_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"favorite_course_title","state":errors.length > 0 ? false : null,"name":"favorite_course_title","placeholder":"Enter Your Favorite Course Section Title"},model:{value:(_vm.form.favorite_course_title),callback:function ($$v) {_vm.$set(_vm.form, "favorite_course_title", $$v)},expression:"form.favorite_course_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Success Story Section Title","label-for":"success_story_section_title"}},[_c('validation-provider',{attrs:{"name":"success_story_section_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"success_story_section_title","state":errors.length > 0 ? false : null,"name":"success_story_section_title","placeholder":"Enter Your Success Story Section Title"},model:{value:(_vm.form.success_story_section_title),callback:function ($$v) {_vm.$set(_vm.form, "success_story_section_title", $$v)},expression:"form.success_story_section_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Marketplace Section Title","label-for":"marketplace_section_title"}},[_c('validation-provider',{attrs:{"name":"marketplace_section_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"marketplace_section_title","state":errors.length > 0 ? false : null,"name":"marketplace_section_title","placeholder":"Enter Your Marketplace Section Title"},model:{value:(_vm.form.marketplace_section_title),callback:function ($$v) {_vm.$set(_vm.form, "marketplace_section_title", $$v)},expression:"form.marketplace_section_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Enter Your Marketplace Section Description","label-for":"marketplace_section_description"}},[_c('validation-provider',{attrs:{"name":"marketplace_section_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"marketplace_section_description","state":errors.length > 0 ? false : null,"name":"marketplace_section_description","placeholder":"Enter Your Enter Your Marketplace Section Description","rows":"4"},model:{value:(_vm.form.marketplace_section_description),callback:function ($$v) {_vm.$set(_vm.form, "marketplace_section_description", $$v)},expression:"form.marketplace_section_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Select Marketplace","label-for":"marketplace_id"}},[_c('validation-provider',{attrs:{"name":"marketplace_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"marketplace_id","placeholder":"Select Marketplace","multiple":"","options":_vm.marketplaces,"label":"name","reduce":function (option) { return option.id; }},model:{value:(_vm.form.marketplace_id),callback:function ($$v) {_vm.$set(_vm.form, "marketplace_id", $$v)},expression:"form.marketplace_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Meta Title","label-for":"meta_title"}},[_c('validation-provider',{attrs:{"name":"meta_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"meta_title","state":errors.length > 0 ? false : null,"name":"meta_title","placeholder":"Enter Your Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Meta Description","label-for":"meta_description"}},[_c('validation-provider',{attrs:{"name":"meta_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"meta_description","state":errors.length > 0 ? false : null,"name":"meta_description","placeholder":"Enter Your Meta Description","rows":"2"},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Update ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }