<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <!-- $store.state.base_url + 'images/CIT-13-Years.jpg' -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <!-- email -->
                <b-form-group label="Title" label-for="title">
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                  >
                    <b-form-input
                      id="title"
                      v-model="form.title"
                      :state="errors.length > 0 ? false : null"
                      name="title"
                      placeholder="Enter Your Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group label="Description" label-for="description">
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="form.description"
                      :state="errors.length > 0 ? false : null"
                      name="description"
                      placeholder="Enter Your Description"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group
                  label="Marketplace Section Title"
                  label-for="marketplace_section_title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="marketplace_section_title"
                    rules="required"
                  >
                    <b-form-input
                      id="marketplace_section_title"
                      v-model="form.marketplace_section_title"
                      :state="errors.length > 0 ? false : null"
                      name="marketplace_section_title"
                      placeholder="Enter Your Marketplace Section Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group
                  label="Marketplace Section Description"
                  label-for="marketplace_section_description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="marketplace_section_description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="marketplace_section_description"
                      v-model="form.marketplace_section_description"
                      :state="errors.length > 0 ? false : null"
                      name="marketplace_section_description"
                      placeholder="Enter Your Marketplace Section Description"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="col-md-12">
                <b-form-group
                  label="Select Marketplace"
                  label-for="marketplace_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="marketplace_id"
                    rules="required"
                  >
                    <v-select
                      id="marketplace_id"
                      v-model="form.marketplace_id"
                      placeholder="Select Marketplace"
                      multiple
                      :options="marketplaces"
                      label="name"
                      :reduce="(option) => option.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="border rounded p-2 mb-2">
              <h4 class="mb-1">Video Setting</h4>

              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    :src="video_thumbnail"
                    height="174"
                    width="310"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-form-group
                    label="Video Thumbnail"
                    label-for="video_thumbnail"
                  >
                    <b-form-file
                      id="video_thumbnail"
                      accept=".jpg, .png, .gif"
                      @change="loadImage($event)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                  </b-form-group>
                  <!-- email -->
                  <b-form-group label="Video ID" label-for="video_id">
                    <validation-provider
                      #default="{ errors }"
                      name="video_id"
                      rules="required"
                    >
                      <b-form-input
                        id="video_id"
                        v-model="form.video_id"
                        :state="errors.length > 0 ? false : null"
                        name="video_id"
                        placeholder="Enter Your Video ID"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-media-body>
              </b-media>
            </div>
          </div>
        </div>
        <!-- submit buttons -->
        <b-button type="submit" variant="primary" @click="validationForm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
export default {
  props:['marketplaces'],
  data() {
    return {
      form: {
        title: "",
        description: "",
        marketplace_section_title: "",
        marketplace_section_description: "",
        marketplace_id: [],
        video_id: "",
        video_thumbnail: null,
      },
      video_thumbnail: null,
      required,
      email,
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    async getBanner() {
      const freelancing = await this.callApi("get", "/app/freelancing/show");
      if (freelancing.status == 200) {
        this.form.title = freelancing.data.freelancing.title;
        this.form.description = freelancing.data.freelancing.description;
        this.form.marketplace_section_title =
          freelancing.data.freelancing.marketplace_section_title;
        this.form.marketplace_section_description =
          freelancing.data.freelancing.marketplace_section_description;
        this.form.video_id = freelancing.data.freelancing.video_id;
        this.form.marketplace_id = [];
        freelancing.data.freelancing.marketplaces.forEach((marketplace) => {
          this.form.marketplace_id.push(marketplace.id);
        });
        this.video_thumbnail =
          this.$store.state.base_url +
          (freelancing.data.freelancing.video_thumbnail
            ? freelancing.data.freelancing.video_thumbnail
            : "images/freelancing/default.jpg");
      }
    },

    loadImage(e) {
      this.form.video_thumbnail = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.video_thumbnail = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Update() {
      const fd = new FormData();
      if (this.form.video_thumbnail) {
        fd.append(
          "video_thumbnail",
          this.form.video_thumbnail,
          this.form.video_thumbnail.name
        );
      }
      fd.append("title", this.form.title);
      fd.append("description", this.form.description);
      fd.append("video_id", this.form.video_id);
      fd.append(
        "marketplace_section_title",
        this.form.marketplace_section_title
      );
      fd.append(
        "marketplace_section_description",
        this.form.marketplace_section_description
      );

      for (let i = 0; i < this.form.marketplace_id.length; i++) {
        let marketplace_id = this.form.marketplace_id[i];
        fd.append("marketplace_id[" + i + "]", marketplace_id);
      }

      axios
        .post("/app/freelancing/update", fd)
        .then((res) => {
          this.getBanner();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
            if (e.response.data.errors.marketplace_section_title)
              this.e(e.response.data.errors.marketplace_section_title[0]);
            if (e.response.data.errors.marketplace_section_description)
              this.e(e.response.data.errors.marketplace_section_description[0]);
            if (e.response.data.errors.marketplace_id)
              this.e(e.response.data.errors.marketplace_id[0]);
            if (e.response.data.errors.video_id)
              this.e(e.response.data.errors.video_id[0]);
            if (e.response.data.errors.video_thumbnail)
              this.e(e.response.data.errors.video_thumbnail[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },

  components: {
    vSelect,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
