<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Title" label-for="title">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="form.title"
                  :state="errors.length > 0 ? false : null"
                  name="title"
                  placeholder="Enter Your Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Description" label-for="description">
              <validation-provider #default="{ errors }" name="description">
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                  placeholder="Enter Your Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Freelancing For Whom Title"
              label-for="freelancing_for_whom_title"
            >
              <validation-provider
                #default="{ errors }"
                name="freelancing_for_whom_title"
                rules="required"
              >
                <b-form-input
                  id="freelancing_for_whom_title"
                  v-model="form.freelancing_for_whom_title"
                  :state="errors.length > 0 ? false : null"
                  name="freelancing_for_whom_title"
                  placeholder="Enter Your Freelancing For Whom Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Enter Your Freelancing For Whom Description"
              label-for="freelancing_for_whom_description"
            >
              <validation-provider
                #default="{ errors }"
                name="freelancing_for_whom_description"
              >
                <b-form-textarea
                  id="freelancing_for_whom_description"
                  v-model="form.freelancing_for_whom_description"
                  :state="errors.length > 0 ? false : null"
                  name="freelancing_for_whom_description"
                  placeholder="Enter Your Enter Your Freelancing For Whom Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              label="Select Work For Whom"
              label-for="work_for_whom_id"
            >
              <validation-provider
                #default="{ errors }"
                name="work_for_whom_id"
                rules="required"
              >
                <v-select
                  id="work_for_whom_id"
                  v-model="form.work_for_whom_id"
                  placeholder="Select Work For Whom"
                  multiple
                  :options="freelancing_for_whoms"
                  label="name"
                  :reduce="(option) => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Cit Initiatives Section Title"
              label-for="cit_initiatives_section_title"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_initiatives_section_title"
                rules="required"
              >
                <b-form-input
                  id="cit_initiatives_section_title"
                  v-model="form.cit_initiatives_section_title"
                  :state="errors.length > 0 ? false : null"
                  name="cit_initiatives_section_title"
                  placeholder="Enter Your Cit Initiatives Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Favorite Course Section Title"
              label-for="favorite_course_title"
            >
              <validation-provider
                #default="{ errors }"
                name="favorite_course_title"
                rules="required"
              >
                <b-form-input
                  id="favorite_course_title"
                  v-model="form.favorite_course_title"
                  :state="errors.length > 0 ? false : null"
                  name="favorite_course_title"
                  placeholder="Enter Your Favorite Course Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Success Story Section Title"
              label-for="success_story_section_title"
            >
              <validation-provider
                #default="{ errors }"
                name="success_story_section_title"
                rules="required"
              >
                <b-form-input
                  id="success_story_section_title"
                  v-model="form.success_story_section_title"
                  :state="errors.length > 0 ? false : null"
                  name="success_story_section_title"
                  placeholder="Enter Your Success Story Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Marketplace Section Title"
              label-for="marketplace_section_title"
            >
              <validation-provider
                #default="{ errors }"
                name="marketplace_section_title"
                rules="required"
              >
                <b-form-input
                  id="marketplace_section_title"
                  v-model="form.marketplace_section_title"
                  :state="errors.length > 0 ? false : null"
                  name="marketplace_section_title"
                  placeholder="Enter Your Marketplace Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Enter Your Marketplace Section Description"
              label-for="marketplace_section_description"
            >
              <validation-provider
                #default="{ errors }"
                name="marketplace_section_description"
              >
                <b-form-textarea
                  id="marketplace_section_description"
                  v-model="form.marketplace_section_description"
                  :state="errors.length > 0 ? false : null"
                  name="marketplace_section_description"
                  placeholder="Enter Your Enter Your Marketplace Section Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group label="Select Marketplace" label-for="marketplace_id">
              <validation-provider
                #default="{ errors }"
                name="marketplace_id"
                rules="required"
              >
                <v-select
                  id="marketplace_id"
                  v-model="form.marketplace_id"
                  placeholder="Select Marketplace"
                  multiple
                  :options="marketplaces"
                  label="name"
                  :reduce="(option) => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-md-12">
            <b-form-group label="Meta Title" label-for="meta_title">
              <validation-provider
                #default="{ errors }"
                name="meta_title"
                rules="required"
              >
                <b-form-input
                  id="meta_title"
                  v-model="form.meta_title"
                  :state="errors.length > 0 ? false : null"
                  name="meta_title"
                  placeholder="Enter Your Meta Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Meta Description" label-for="meta_description">
              <validation-provider
                #default="{ errors }"
                name="meta_description"
                rules="required"
              >
                <b-form-textarea
                  id="meta_description"
                  v-model="form.meta_description"
                  :state="errors.length > 0 ? false : null"
                  name="meta_description"
                  placeholder="Enter Your Meta Description"
                  rows="2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <!-- submit buttons -->
        <b-button type="submit" variant="primary" @click="validationForm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
export default {
  props: ["marketplaces"],
  data() {
    return {
      form: {
        marketplace_id: [],
        work_for_whom_id: [],
        title: "",
        description: "",
        freelancing_for_whom_title: "",
        freelancing_for_whom_description: "",
        marketplace_section_title: "",
        marketplace_section_description: "",
        cit_initiatives_section_title: "",
        favorite_course_title: "",
        success_story_section_title: "",
        meta_title: "",
        meta_description: "",
      },
      freelancing_for_whoms: [],
      required,
      email,
    };
  },
  mounted() {
    this.getFreelancingForWhom();
    this.getFreelancingPage();
  },
  methods: {
    async getFreelancingPage() {
      const freelancing_page = await this.callApi(
        "get",
        "/app/freelancing/page/show"
      );
      if (freelancing_page.status == 200) {
        this.form.title = freelancing_page.data.freelancing_page.title;
        this.form.description =
          freelancing_page.data.freelancing_page.description;
        this.form.freelancing_for_whom_title =
          freelancing_page.data.freelancing_page.freelancing_for_whom_title;
        this.form.freelancing_for_whom_description =
          freelancing_page.data.freelancing_page.freelancing_for_whom_description;
        this.form.marketplace_section_title =
          freelancing_page.data.freelancing_page.marketplace_section_title;
        this.form.marketplace_section_description =
          freelancing_page.data.freelancing_page.marketplace_section_description;
        this.form.cit_initiatives_section_title =
          freelancing_page.data.freelancing_page.cit_initiatives_section_title;
        this.form.favorite_course_title =
          freelancing_page.data.freelancing_page.favorite_course_title;
        this.form.success_story_section_title =
          freelancing_page.data.freelancing_page.success_story_section_title;
        this.form.meta_title =
          freelancing_page.data.freelancing_page.meta_title;
        this.form.meta_description =
          freelancing_page.data.freelancing_page.meta_description;
        this.form.marketplace_id = [];

        freelancing_page.data.freelancing_page.marketplaces.forEach(
          (marketplace) => {
            this.form.marketplace_id.push(marketplace.id);
          }
        );
        this.form.work_for_whom_id = [];
        freelancing_page.data.freelancing_page.work_for_whoms.forEach(
          (work) => {
            this.form.work_for_whom_id.push(work.id);
          }
        );
      }
    },
    async getFreelancingForWhom() {
      const freelancing_page = await this.callApi(
        "post",
        "/app/freelancing/for/whom/get"
      );
      if (freelancing_page.status == 200) {
        this.freelancing_for_whoms =
          freelancing_page.data.freelancing_for_whoms;
      }
    },
    Update() {
      axios
        .post("/app/freelancing/page/update", this.form)
        .then((res) => {
          this.getFreelancingPage();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.favorite_course_title)
              this.e(e.response.data.errors.favorite_course_title[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    vSelect,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
