<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <b-card>
      <b-tabs content-class="mt-2" justified>
        <b-tab active title="Page">
          <Page :marketplaces="marketplaces" />
        </b-tab>
        <b-tab title="Freelancing">
          <b-card-text>
            <Freelancing :marketplaces="marketplaces" />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BLink } from "bootstrap-vue";
import Page from "./partials/Page.vue";
import Freelancing from "./partials/Freelancing.vue";
export default {
  components: {
    Freelancing,
    Page,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
  },
  mounted() {
    this.$store.dispatch("getMarketplace");
  },
  computed: {
    marketplaces() {
      return this.$store.getters.getMarketplace;
    },
  },
};
</script>

<style></style>
